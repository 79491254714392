import React from "react";
import ButtonComponent from "./ButtonComponent";

export default function Popup({ setIsPopupVisible, message}) {
	return (
		<div 
			className="fixed inset-0 bg-black bg-opacity-50 z-20 flex justify-center items-center"
			onClick={() => setIsPopupVisible(false)}
		>
			<div
				className="p-4 bg-white border border-gray-300 mx-5 rounded-lg shadow-lg text-sm max-w-sm"
				onClick={(e) => e.stopPropagation()}
			>
				<p>
					{message}
				</p>
				<ButtonComponent
					onClick={() => setIsPopupVisible(false)}
					text="Close"
					className="mt-2"
					theme="cancel"
				/>
			</div>
		</div>
	)
}