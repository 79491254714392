import React, { useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { addLocation } from '../services/apiServices';
import useTokenValidator from '../services/useTokenValidator';
import TokenManager from '../services/tokenManager';
import LocationBasicInformation from '../components/locations/LocationBasicInformation';
import LocationKeywords from '../components/locations/LocationKeywords';
import LocationAddress from '../components/locations/LocationAddress';
import LocationImage from '../components/locations/LocationImage';
import LocationSettings from '../components/locations/LocationSettings';
import LocationFTD from '../components/locations/LocationFTD';
import Popup from '../components/Popup';
import LocationSectionTile from '../components/locations/LocationSectionTile';
import { InformationCircleIcon, KeyIcon, LocationMarkerIcon, PhotographIcon, CogIcon, CashIcon } from '@heroicons/react/solid';
import ButtonComponent from '../components/ButtonComponent';
import Header from '../components/Header';
import { useNotification } from '../services/NotificationContext';

export default function AddLocation() {
  const navigate = useNavigate();
  const showNotification = useNotification();
  const [error, setError] = useState('');
  const [keywordInput, setKeywordInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [googleImages, setGoogleImages] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [section, setSection] = useState('basic');
  const sections = ["basic", "keywords", "address", "image", "settings", "ftd"];
  const [popupMessage, setPopupMessage] = useState('');
  const scrollRef = useRef(null);
  const [sectionStatus, setSectionStatus] = useState({
    basic: false,
    keywords: false,
    address: false,
    image: false,
    settings: true,
    ftd: true,
  });
  const [locationData, setLocationData] = useState({
    displayName: '',
    description: '',
    keywords: [],
    address: '',
    imageUrl: '',
    useGoogleImage: true,
    commission: 5,
    discount: 10,
    minSpend: 4.99,
    hideQrCode: false,
    googlePlaceUrl: '',
    googlePlaceId: '',
    longitude: 0,
    latitude: 0,
    firstTimeDiscount: false,
    firstTimeDiscountAmount: 15,
    firstTimeDiscountNote: '',
    countsTowardsLimit: false,
  });

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -110, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 110, behavior: "smooth" });
  };

  const previousSection = () => {
    const currentIndex = sections.indexOf(section);
    if (currentIndex > 0) {
      setSection(sections[currentIndex - 1]);
    }
  };
  
  const nextSection = () => {
    const currentIndex = sections.indexOf(section);
    if (currentIndex < sections.length - 1) {
      setSection(sections[currentIndex + 1]);
    }
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const updateStatus = useCallback((section, isComplete) => {
    setSectionStatus((prevStatus) => ({
      ...prevStatus,
      [section]: isComplete,
    }));
  }, []);

  useTokenValidator();

  const handleAddressSelect = (place) => {
    const address = place.formatted_address;
    const photos = place.photos || [];
    setLocationData((prevData) => ({
      ...prevData,
      address,
      googlePlaceUrl: place.url,
      longitude: place.geometry.location.lng(),
      latitude: place.geometry.location.lat(),
      googlePlaceId: place.place_id,
      imageUrl: photos.length > 0 ? photos[0].getUrl() : '',
    }));
    setIsAddressValid(true);
    updateStatus('address', true);
    setGoogleImages(photos.map((photo) => photo.getUrl()));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocationData({
      ...locationData,
      [name]: value,
    });

    if (name === 'address') {
      setIsAddressValid(false);
      updateStatus('address', false);
    }
  };

  const handleSliderChange = (name, value) => {
    setLocationData({ ...locationData, [name]: value });
  };

  const handleAdjustMinSpend = (amount) => {
    const newValue = Math.max(4.99, parseFloat((locationData.minSpend + amount).toFixed(2)));
    handleChange({
      target: {
        name: 'minSpend',
        value: newValue,
      },
    });
  };

  const handleAddKeyword = () => {
    const trimmedKeyword = keywordInput.trim();
  
    const existingKeywordsLowercase = locationData.keywords.map((keyword) => keyword.toLowerCase());
  
    if (
      trimmedKeyword !== '' && 
      locationData.keywords.length < 10 && 
      !existingKeywordsLowercase.includes(trimmedKeyword.toLowerCase())
    ) {
      setLocationData({
        ...locationData,
        keywords: [...locationData.keywords, trimmedKeyword],
      });
      setKeywordInput('');
    }
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    setLocationData({
      ...locationData,
      keywords: locationData.keywords.filter((keyword) => keyword !== keywordToRemove),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (locationData.firstTimeDiscount && (locationData.firstTimeDiscountAmount < locationData.discount + 5)) {
      setError('First-time discount must be at least 5% bigger than the regular discount');
      scrollToTop();
      return;
    }
  
    setIsLoading(true);

    try {
      addLocation({
        access_token: TokenManager.getToken(),
        location_name: locationData.displayName,
        key_words: locationData.keywords,
        description: locationData.description,
        address: locationData.address,
        latitude: locationData.latitude,
        longitude: locationData.longitude,
        google_place_id: locationData.googlePlaceId,
        google_place_url: locationData.googlePlaceUrl,
        discount_amount: locationData.discount,
        commission: locationData.commission,
        min_spend: locationData.minSpend,
        image_settings: {
          url: locationData.imageUrl,
          use_google_image: locationData.useGoogleImage,
        },
        hide_qr_code: locationData.hideQrCode,
        ftcd: locationData.firstTimeDiscount,
        ftcd_note: locationData.firstTimeDiscountNote,
        ftcd_amount: locationData.firstTimeDiscountAmount,
        ftcd_counts_towards_limit: locationData.countsTowardsLimit,
      })
      .then((response) => {
        if (response.status === 200 && response.data.status === 'success') {
          setError('');
          showNotification('Location added successfully', 'success');
          navigate('/dashboard/locations?');
        } else {
          setError(response.message || 'Adding failed');
          scrollToTop(); 
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response?.data?.message || 'Adding failed');
        scrollToTop(); 
        setIsLoading(false);
      });
    } catch (err) {
      console.error(err);
      setError('Adding failed');
      scrollToTop(); 
      setIsLoading(false);
    }
  };
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  
  return (
    <div className="relative bg-white flex items-center flex-col min-h-screen">
      <Header />
      {/* Page Title */}
      <h1 className="text-3xl sm:text-4xl font-bold text-center mb-8 mt-4">Add a New Location</h1>

      {/* Error Message */}
      {error && <p className="text-red-500 mb-4">{error}</p>}

      {/* Popup */}
      {isPopupVisible && (
        <Popup
          setIsPopupVisible={setIsPopupVisible}
          message={popupMessage}
        />
      )}

      {/* Spinner */}
      <div className="relative flex items-center w-full max-w-2xl overflow-hidden mb-5">

        {/* Left Scroll Arrow */}
        <button
          className="absolute left-0 hidden sm:block bg-gray-200 rounded-full p-2 shadow-md hover:bg-gray-300 h-10 w-10"
          onClick={scrollLeft}
          style={{ zIndex: 10 }}
        >
        &lt;
        </button>

        {/* Scrollable Container */}
        <div
          ref={scrollRef}
          className="flex overflow-x-auto items-center gap-2 w-full px-3"
        >
          <LocationSectionTile
            title="Basic Information"
            onClick={() => setSection("basic")}
            icon={<InformationCircleIcon />}
            selected={section === "basic"}
            completed={sectionStatus.basic}         
            />
          <LocationSectionTile
            title="Keywords"
            onClick={() => setSection("keywords")}
            icon={<KeyIcon />}
            selected={section === "keywords"}
            completed={sectionStatus.keywords}         
            />
          <LocationSectionTile
            title="Address"
            onClick={() => setSection("address")}
            icon={<LocationMarkerIcon />}
            selected={section === "address"}
            completed={sectionStatus.address}                
            />
          <LocationSectionTile
            title="Image"
            onClick={() => setSection("image")}
            icon={<PhotographIcon />}
            selected={section === "image"}
            completed={sectionStatus.image}   
          />
          <LocationSectionTile
            title="Settings"
            onClick={() => setSection("settings")}
            icon={<CogIcon />}
            selected={section === "settings"}
            completed={sectionStatus.settings}                 
            />
          <LocationSectionTile
            title="FTD"
            onClick={() => setSection("ftd")}
            icon={<CashIcon />}
            selected={section === "ftd"}
            completed={sectionStatus.ftd}             
            />
        </div>

        {/* Right Scroll Arrow */}
        <button
          className="absolute hidden sm:block right-0 bg-gray-200 rounded-full p-2 shadow-md hover:bg-gray-300 h-10 w-10"
          onClick={scrollRight}
          style={{ zIndex: 10 }}
        >
          &gt;
        </button>
      </div>

      <div className="w-full max-w-2xl mx-auto flex-grow mb-5 px-5">
        <form onSubmit={handleSubmit} className="space-y-4">

          {/* Section */}
          {
            section === "basic" ? (
              <LocationBasicInformation 
                locationData={locationData} 
                handleChange={handleChange} 
                onClick={(e) => {
                  togglePopup();
                  setPopupMessage(e)}}
                updateStatus={updateStatus}
              />
            ) : section === "keywords" ? (
              <LocationKeywords
                locationData={locationData}
                handleAddKeyword={handleAddKeyword}
                keywordInput={keywordInput}
                setKeywordInput={setKeywordInput}
                handleRemoveKeyword={handleRemoveKeyword}
                onClick={(e) => {
                  togglePopup();
                  setPopupMessage(e)}}
                updateStatus={updateStatus}
              />
            ) : section === "address" ? (
              <LocationAddress
                locationData={locationData}
                handleAddressSelect={handleAddressSelect}
                handleChange={handleChange}
                onClick={(e) => {
                  togglePopup();
                  setPopupMessage(e)}}
                updateStatus={updateStatus}
              />
            ) : section === "image" ? (
              isAddressValid ? (
                <LocationImage
                  locationData={locationData}
                  setLocationData={setLocationData}
                  googleImages={googleImages}
                  onClick={(e) => {
                    togglePopup();
                    setPopupMessage(e);
                  }}
                  updateStatus={updateStatus}
                />
              ) : (
                <div className="text-center w-full">Please set the address first</div>
              )
            ) : section === "settings" ? (
              <LocationSettings
                locationData={locationData}
                setLocationData={setLocationData}
                handleSliderChange={handleSliderChange}
                handleAdjustMinSpend={handleAdjustMinSpend}
                onClick={(e) => {
                  togglePopup();
                  setPopupMessage(e)}}
                updateStatus={updateStatus}
              /> 
            ) : section === "ftd" ? (
              <LocationFTD
                locationData={locationData}
                setLocationData={setLocationData}
                handleSliderChange={handleSliderChange}
                onClick={(e) => {
                  togglePopup();
                  setPopupMessage(e)}} 
                updateStatus={updateStatus}
                  />
            ) : (
              <div>Please select a section</div>
            )
          }

          {/* Form Actions Section */}
          <div className="flex flex-col sm:flex-row justify-between mt-4 flex-wrap">
              <div className="flex space-x-4 justify-center sm:justify-start mb-4 sm:mb-0">
                <ButtonComponent
                  text="Previous"
                  type="button"
                  onClick={() => {
                    previousSection();
                    scrollLeft();
                    }}
                  theme="navigation"
                />
                <ButtonComponent
                  text="Next"
                  type="button"
                  onClick={() => {
                    nextSection();
                    scrollRight();
                    }}                  
                  theme="navigation"
                />
              </div>

              <div className="flex flex-col-reverse sm:flex-row items-center sm:space-x-2">
                <ButtonComponent
                  text="Cancel"
                  type="button"
                  onClick={() => navigate('/dashboard/locations')}
                  theme="cancel"
                  className='w-full sm:w-auto'
                />
                <ButtonComponent
                  text="Save"
                  type="submit"
                  theme="primary"
                  className='w-full sm:w-auto mb-2 sm:mb-0'
                  // onClick={}
                />
                {/* <ButtonComponent
                  text={isLoading ? 'Submitting...' : 'Submit for Approval'}
                  type="submit"
                  theme="accept"
                  disabled={isLoading || !Object.values(sectionStatus).every((status) => status)}
                  className={`w-full mb-2 sm:mb-0 sm:w-auto ${
                    isLoading || !Object.values(sectionStatus).every((status) => status)
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                  title={
                    !Object.values(sectionStatus).every((status) => status)
                      ? 'Please complete all sections before submitting'
                      : ''
                  }
                /> */}
              </div>
            </div>
          </form>
      </div>
    </div>
  );
}