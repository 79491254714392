import React from "react";
import {QuestionMarkCircleIcon } from '@heroicons/react/solid';

export default function LocationFTD( {setLocationData, handleSliderChange, locationData, onClick} ) {
	return (
		<div className="border-b border-gray-300 pb-4">

			<h2 className="text-xl font-semibold mb-4 flex items-center">Offer a First-Time Discount?
				<QuestionMarkCircleIcon 
				className="h-5 w-5 inline-block text-gray-500 ml-1 cursor-pointer"
				onClick={() => onClick("First-time customers visiting your establishment through Nusmark are eligible for an exclusive introductory discount, which can be configured by you in this section.")}/>
			</h2>

			<div className="mb-4">
				<label className="flex items-center space-x-2">
					<input
						type="radio"
						name="firstTimeDiscount"
						value="yes"
						checked={locationData.firstTimeDiscount}
						onChange={() => setLocationData({ ...locationData, firstTimeDiscount: true })}
					/>
					<span>Yes</span>
				</label>

				<label className="flex items-center space-x-2">
					<input
						type="radio"
						name="firstTimeDiscount"
						value="no"
						checked={!locationData.firstTimeDiscount}
						onChange={() => setLocationData({ ...locationData, firstTimeDiscount: false })}
					/>
					<span>No</span>
				</label>
			</div>

			{/* First-Time Discount Slider */}
			{locationData.firstTimeDiscount && (
				<>
					<div className="mb-4">
						<label className="block mb-2 text-sm font-medium">
							Discount (2.5% increments, min 15%)
						</label>
						<input
							type="range"
							min="15"
							max="75"
							step={2.5}
							value={locationData.firstTimeDiscountAmount}
							onChange={(e) => handleSliderChange("firstTimeDiscountAmount", parseFloat(e.target.value))}
							className="w-full"
						/>
						<p>{locationData.firstTimeDiscountAmount}%</p>
					</div>

					{/* Discount Note */}
					<div className="mb-4 relative">
						<label className="block mb-2 text-sm font-medium">Discount Note</label>
						<textarea
							name="discountNote"
							value={locationData.firstTimeDiscountNote}
							onChange={(e) => {
								if (e.target.value.length <= 50) {
									setLocationData({
										...locationData,
										firstTimeDiscountNote: e.target.value,
									});
								}
							}}
							className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
							placeholder="Enter details and conditions about the discount..."
						/>
						{/* Character Count */}
						<p className="text-sm text-gray-500 absolute bottom-1 right-2">
							{locationData.firstTimeDiscountNote.length}/50
						</p>
					</div>

					{/* Counts Towards Daily Limit */}
					<div className="mb-4">
						<label className="flex items-center space-x-2">
							<input
								type="checkbox"
								name="countsTowardsLimit"
								checked={locationData.countsTowardsLimit}
								onChange={(e) =>
									setLocationData({
										...locationData,
										countsTowardsLimit: e.target.checked,
									})
								}
							/>
							<span>Counts Towards Daily Limit</span>
						</label>
					</div>
				</>
			)}
		</div>
	)
}