import React from "react";

export default function LocationSectionTile({ icon, title, onClick, className, selected, completed }) {
	return (
		<button
			onClick={onClick}
			className={`w-28 h-12 items-center justify-center sm:w-32 sm:h-28 relative flex flex-shrink-0 flex-row sm:flex-col sm:items-start sm:justify-between text-gray-500 border  rounded-lg shadow-md hover:shadow-lg hover:bg-gray-200 ${className} ${completed && "bg-lime-500 text-white"} ${selected && "border-blue-300 border-2"} transition duration-300 p-3 `}		
			>
			{/* Icon Section */}
			<div className="flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10">
				{icon}
			</div>

			{/* Title Section */}
			<span
				className={`text-xs ml-1 sm:text-sm font-bold text-left w-full text-gray-500 whitespace-nowrap text-ellipsis overflow-hidden ${completed && "text-white"}`}
				style={{ maxHeight: "1.5rem", lineHeight: "1.5rem" }}
			>
				{title}
			</span>
		</button>
	);
}
