import React, { useEffect } from "react";
import { PlusIcon, XIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';


export default function LocationKeywords( {locationData, handleAddKeyword, keywordInput, setKeywordInput, handleRemoveKeyword, onClick, updateStatus} ) {
		useEffect(() => {
			const isComplete = locationData.keywords.length > 0;
			updateStatus("keywords", isComplete);
		}, [locationData.keywords, updateStatus]);

  return (
		<div className="border-b border-gray-300 pb-4">
			<h2 className="text-xl font-semibold mb-4">Keywords
			<QuestionMarkCircleIcon 
				className="h-5 w-5 inline-block text-gray-500 ml-1 cursor-pointer"
				onClick={() => onClick("In this section you can add relevant keywords to your location. These keywords will help users find your location when they search for it.")}
				/>
			</h2>
			<label className="block mb-1 text-md font-medium">Keywords (Max 10)</label>
			<div className="flex items-center mb-2">
				<input
					type="text"
					value={keywordInput}
					onChange={(e) => setKeywordInput(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
							handleAddKeyword();
						}
					}}
					className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
				/>
				<button
					type="button"
					onClick={handleAddKeyword}
					className="text-slate-500 text-nowrap flex flex-row justify-start items-center border border-blue-400 hover:bg-blue-600 hover:text-white text-base px-3 py-2 rounded ml-2 transition duration-300"
					disabled={locationData.keywords.length >= 10}
				>
					<PlusIcon className="h-5 w-5" />
					<p className="ml-2">Add Keyword</p>
				</button>
			</div>
			<div className="flex flex-wrap gap-2 justify-start items-center">
				{locationData.keywords.map((keyword, index) => (
					<span
						key={index}
						className="px-3 py-1 bg-gray-100 border border-gray-300 text-black rounded-md flex items-center"
					>
						{keyword}
						<button
							type="button"
							onClick={() => handleRemoveKeyword(keyword)}
							className="ml-2 text-red-900 font-bold"
						>
							<XIcon className="h-4 w-4" />
						</button>
					</span>
				))}
			</div>
		</div>
  );
}