import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { loadLocation, editLocation, deleteLocation, checkAccessName } from '../services/apiServices';
import useTokenValidator from '../services/useTokenValidator';
import TokenManager from '../services/tokenManager';
import LocationBasicInformation from '../components/locations/LocationBasicInformation';
import LocationKeywords from '../components/locations/LocationKeywords';
import LocationAddress from '../components/locations/LocationAddress';
import LocationImage from '../components/locations/LocationImage';
import LocationSettings from '../components/locations/LocationSettings';
import LocationFTD from '../components/locations/LocationFTD';
import Popup from '../components/Popup';
import LocationSectionTile from '../components/locations/LocationSectionTile';
import { InformationCircleIcon, KeyIcon, LocationMarkerIcon, PhotographIcon, CogIcon, CashIcon } from '@heroicons/react/solid';
import ButtonComponent from '../components/ButtonComponent';
import Header from '../components/Header';
import { useNotification } from '../services/NotificationContext';

export default function Location() {
  const location = useLocation();
  const navigate = useNavigate();
  const showNotification = useNotification();
  const locationId = location.pathname.split('/').pop();
  const [error, setError] = useState('');
  const [keywordInput, setKeywordInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLocationLoading, setIsLocationLoading] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [googleImages, setGoogleImages] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [isNameValid, setIsNameValid] = useState(false);
  const [initialAccessName, setInitialAccessName] = useState('');
  const [initialLocationName, setInitialLocationName] = useState('');
  const [section, setSection] = useState('basic');
  const sections = ["basic", "keywords", "address", "image", "settings", "ftd"];
  const [popupMessage, setPopupMessage] = useState('');
  const scrollRef = useRef(null);
  const [sectionStatus, setSectionStatus] = useState({
    basic: false,
    keywords: false,
    address: false,
    image: false,
    settings: true,
    ftd: true,
  });
  const [locationData, setLocationData] = useState({
    locationId: '',
    displayName: '',
    description: '',
    accessName: '',
    accessCode: '',
    accessUrl: '',
    keywords: [],
    address: '',
    imageUrl: '',
    useGoogleImage: true,
    googlePlaceUrl: '',
    googlePlaceId: '',
    longitude: 0,
    latitude: 0,
    commission: 5,
    discount: 10,
    minSpend: 4.99,
    hideQrCode: false,
    firstTimeDiscount: false,
    firstTimeDiscountAmount: 15,
    firstTimeDiscountNote: '',
    countsTowardsLimit: false,
  });

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -110, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 110, behavior: "smooth" });
  };

  const previousSection = () => {
    const currentIndex = sections.indexOf(section);
    if (currentIndex > 0) {
      setSection(sections[currentIndex - 1]);
    }
  };
  
  const nextSection = () => {
    const currentIndex = sections.indexOf(section);
    if (currentIndex < sections.length - 1) {
      setSection(sections[currentIndex + 1]);
    }
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const updateStatus = useCallback((section, isComplete) => {
    setSectionStatus((prevStatus) => ({
      ...prevStatus,
      [section]: isComplete,
    }));
  }, []);

  const isTokenLoading = useTokenValidator();

  useEffect(() => {
    if (isTokenLoading) return;

    setIsLocationLoading(true);
    loadLocation({
      access_token: TokenManager.getToken(),
      location_id: locationId,
    })
    .then((response) => {
      if (response.data) {
        
        setLocationData({
          locationId: response.data.location.location_id,
          displayName: response.data.location.location_name,
          description: response.data.location.description || '',
          accessName: response.data.location.access_name || '',
          accessCode: response.data.location.access_passcode || '',
          keywords: response.data.location.key_words || [],
          address: response.data.location.address,
          imageUrl: response.data.location.image_settings.url,
          useGoogleImage: response.data.location.image_settings.use_google_image,
          googlePlaceUrl: response.data.location.google_place_url,
          googlePlaceId: response.data.location.google_place_id,
          longitude: response.data.location.longitude,
          latitude: response.data.location.latitude,
          commission: response.data.location.discounts.commission,
          discount: response.data.location.discounts.discount_amount,
          minSpend: response.data.location.discounts.min_spend,
          hideQrCode: response.data.location.discounts.hide_qr_code,
          firstTimeDiscount: response.data.location.discounts.ftcd,
          firstTimeDiscountAmount: response.data.location.discounts.ftcd_amount || 0,
          firstTimeDiscountNote: response.data.location.discounts.ftcd_note || '',
          countsTowardsLimit: response.data.location.discounts.ftcd_counts_towards_limit,
        });

        setInitialAccessName(response.data.location.access_name);
        setInitialLocationName(response.data.location.location_name);
        setIsLocationLoading(false);
      } else {
        navigate('/dashboard/locations/not-found');
      }
    })
    .catch((err) => {
      console.error(err);
      setError('Error fetching location');
      setIsLocationLoading(false);
    });
  }, [locationId, navigate, isTokenLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocationData({
      ...locationData,
      [name]: value,
    });

    if (name === 'address') {
      setIsAddressValid(false);
    }
  };

  // Function to check the accessName validity
  const handleAccessName = async (name) => {
    setLocationData({ ...locationData, accessName: name });
    
    if (name === '' || name === initialAccessName) {
      setValidationMessage('');
      setIsNameValid(false);
      return;
    }

    checkAccessName({
      access_token: TokenManager.getToken(),
      access_name: name,
    })
    .then((response) => {
      if (response.data.is_taken === false) {
        setValidationMessage('Name is available');
        setIsNameValid(true);
      } else {
        setValidationMessage('Access name is already taken');
        setIsNameValid(false);
      }
    })
  };

  const handleSliderChange = (name, value) => {
    setLocationData({ ...locationData, [name]: value });
  };

  const handleAdjustMinSpend = (amount) => {
    const newValue = Math.max(4.99, parseFloat((locationData.minSpend + amount).toFixed(2)));
    handleChange({
      target: {
        name: 'minSpend',
        value: newValue,
      },
    });
  };

  const handleAddKeyword = () => {
    const trimmedKeyword = keywordInput.trim();
  
    const existingKeywordsLowercase = locationData.keywords.map((keyword) => keyword.toLowerCase());
  
    if (
      trimmedKeyword !== '' && 
      locationData.keywords.length < 10 && 
      !existingKeywordsLowercase.includes(trimmedKeyword.toLowerCase())
    ) {
      setLocationData({
        ...locationData,
        keywords: [...locationData.keywords, trimmedKeyword],
      });
      setKeywordInput('');
    }
  };

  const handleRemoveKeyword = (keywordToRemove) => {
    setLocationData({
      ...locationData,
      keywords: locationData.keywords.filter((keyword) => keyword !== keywordToRemove),
    });
  };

  const handleAddressSelect = (place) => {
    const address = place.formatted_address;
    const photos = place.photos || [];
    setLocationData((prevData) => ({
      ...prevData,
      address,
      googlePlaceUrl: place.url,
      longitude: place.geometry.location.lng(),
      latitude: place.geometry.location.lat(),
      googlePlaceId: place.place_id,
      imageUrl: photos.length > 0 ? photos[0].getUrl() : '',
    }));
    setIsAddressValid(true);
    setGoogleImages(photos.map((photo) => photo.getUrl()));
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this location?')) {
      setIsLoading(true);
      deleteLocation({
        access_token: TokenManager.getToken(),
        location_id: locationId,
      })
      .then((response) => {
        if (response.data.status === 'success') {
          showNotification('Location deleted successfully', 'success');
          navigate('/dashboard/locations');
        } else {
          showNotification(response.message || 'Deletion failed', 'error');
        }
      })
      .catch((err) => {
        console.error(err);
        showNotification(err.message || 'Deletion failed', 'error');
      })
      .finally(() => setIsLoading(false));
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (locationData.firstTimeDiscount && (locationData.firstTimeDiscountAmount < locationData.discount + 5)) {
      setError('First-time discount must be at least 5% bigger than the regular discount');
      scrollToTop();
      return;
    }    

    setIsLoading(true);

    try {
      const data = {
        access_token: TokenManager.getToken(),
        location_id: locationId,
        location_name: locationData.displayName,
        key_words: locationData.keywords,
        description: locationData.description,
        latitude: locationData.latitude,
        longitude: locationData.longitude,
        google_place_id: locationData.googlePlaceId,
        google_place_url: locationData.googlePlaceUrl,
        discount_amount: locationData.discount,
        commission: locationData.commission,
        min_spend: locationData.minSpend,
        image_settings: {
          url: locationData.imageUrl,
          use_google_image: locationData.useGoogleImage,
        },
        hide_qr_code: locationData.hideQrCode,
        ftcd: locationData.firstTimeDiscount,
        ftcd_amount: locationData.firstTimeDiscountAmount,
        ftcd_note: locationData.firstTimeDiscountNote,
        ftcd_counts_towards_limit: locationData.countsTowardsLimit,
        access_passcode: locationData.accessCode,
      };
      
      if (locationData.accessName !== initialAccessName) {
        data.access_name = locationData.accessName;
      }
      
      editLocation(
        data
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === 'success') {
          setError('');
          showNotification('Location updated successfully', 'success');
          navigate('/dashboard/locations');
        } else {
          setError(response.message || 'Updating failed');
          scrollToTop();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err.response.data.message || 'Updating failed');
        scrollToTop();
        setIsLoading(false);
      });
    } catch (err) {
      console.error(err);
      setError('Updating failed');
      scrollToTop();
      setIsLoading(false);
    }
  };

  return (
    <div className="relative bg-white flex items-center flex-col min-h-screen">
      <Header />
      {isLocationLoading ? (
        <div className="flex items-center justify-center">
          <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          <p className="text-gray-700 text-xl ml-4">Loading Location...</p>
        </div>
      ) : (
        <>
        {/* Page Title */}
        <h1 className="text-2xl sm:text-4xl font-bold text-center mb-8 mt-4">Edit {initialLocationName}</h1>

        {/* Error Message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Popup */}
        {isPopupVisible && (
          <Popup
            setIsPopupVisible={setIsPopupVisible}
            message={popupMessage}
          />
        )}

        {/* Spinner */}
        <div className="relative flex items-center w-full max-w-2xl overflow-hidden mb-5">

          {/* Left Scroll Arrow */}
          <button
            className="absolute hidden sm:block left-0 bg-gray-200 rounded-full p-2 shadow-md hover:bg-gray-300 h-10 w-10"
            onClick={scrollLeft}
            style={{ zIndex: 10 }}
          >
          &lt;
          </button>

          {/* Scrollable Container */}
          <div
            ref={scrollRef}
            className="flex overflow-x-auto items-center gap-2 w-full px-3"
          >
            <LocationSectionTile
              title="Basic Information"
              onClick={() => setSection("basic")}
              icon={<InformationCircleIcon />}
              selected={section === "basic"}
              completed={sectionStatus.basic}            
              />
            <LocationSectionTile
              title="Keywords"
              onClick={() => setSection("keywords")}
              icon={<KeyIcon />}
              selected={section === "keywords"}
              completed={sectionStatus.keywords}         
              />
            <LocationSectionTile
              title="Address"
              onClick={() => setSection("address")}
              icon={<LocationMarkerIcon />}
              selected={section === "address"}
              completed={sectionStatus.address}           
              />
            <LocationSectionTile
              title="Image"
              onClick={() => setSection("image")}
              icon={<PhotographIcon />}
              selected={section === "image"}
              completed={sectionStatus.image}    
            />
            <LocationSectionTile
              title="Settings"
              onClick={() => setSection("settings")}
              icon={<CogIcon />}
              selected={section === "settings"}
              completed={sectionStatus.settings}          
              />
            <LocationSectionTile
              title="FTD"
              onClick={() => setSection("ftd")}
              icon={<CashIcon />}
              selected={section === "ftd"}
              completed={sectionStatus.ftd}                
              />
          </div>

          {/* Right Scroll Arrow */}
          <button
            className="absolute hidden sm:block right-0 bg-gray-200 rounded-full p-2 shadow-md hover:bg-gray-300 h-10 w-10"
            onClick={scrollRight}
            style={{ zIndex: 10 }}
          >
            &gt;
          </button>
        </div>

        <div className="w-full max-w-2xl mx-auto flex-grow mb-5 px-5">
          <form onSubmit={handleSubmit} className="space-y-4">

            {/* Section */}
            {
              section === "basic" ? (
                <LocationBasicInformation 
                  locationData={locationData} 
                  handleChange={handleChange} 
                  onClick={(e) => {
                    togglePopup();
                    setPopupMessage(e)}}
                  updateStatus={updateStatus}
                  existingLocation={true}
                  handleAccessName={handleAccessName}
                  isNameValid={isNameValid}
                  validationMessage={validationMessage}
                />
              ) : section === "keywords" ? (
                <LocationKeywords
                  locationData={locationData}
                  handleAddKeyword={handleAddKeyword}
                  keywordInput={keywordInput}
                  setKeywordInput={setKeywordInput}
                  handleRemoveKeyword={handleRemoveKeyword}
                  onClick={(e) => {
                    togglePopup();
                    setPopupMessage(e)}}
                  updateStatus={updateStatus}
                />
              ) : section === "address" ? (
                <LocationAddress
                  locationData={locationData}
                  handleAddressSelect={handleAddressSelect}
                  handleChange={handleChange}
                  onClick={(e) => {
                    togglePopup();
                    setPopupMessage(e)}}
                  updateStatus={updateStatus}
                />
              ) : section === "image" ? (
                locationData.imageUrl ? (
                  <LocationImage
                    locationData={locationData}
                    setLocationData={setLocationData}
                    googleImages={googleImages}
                    onClick={(e) => {
                      togglePopup();
                      setPopupMessage(e);
                    }}
                    updateStatus={updateStatus}
                    existingLocation={true}
                  />
                ) : (
                  <div className="text-center w-full">Please set the address first</div>
                )
              ) : section === "settings" ? (
                <LocationSettings
                  locationData={locationData}
                  setLocationData={setLocationData}
                  handleSliderChange={handleSliderChange}
                  handleAdjustMinSpend={handleAdjustMinSpend}
                  onClick={(e) => {
                    togglePopup();
                    setPopupMessage(e)}}
                  updateStatus={updateStatus}
                />
              ) : section === "ftd" ? (
                <LocationFTD
                  locationData={locationData}
                  setLocationData={setLocationData}
                  handleSliderChange={handleSliderChange}
                  onClick={(e) => {
                    togglePopup();
                    setPopupMessage(e)}} 
                  updateStatus={updateStatus}
                    />
              ) : (
                <div>Please select a section</div>
              )
            }

          {/* Form Actions Section */}
          <div className="flex flex-col sm:flex-row justify-between mt-4 flex-wrap">
              <div className="flex space-x-4 justify-center sm:justify-start mb-4 sm:mb-0">
                <ButtonComponent
                  text="Previous"
                  type="button"
                  onClick={() => {
                    previousSection();
                    scrollLeft();
                    }}
                  theme="navigation"
                />
                <ButtonComponent
                  text="Next"
                  type="button"
                  onClick={() => {
                    nextSection();
                    scrollRight();
                    }}
                  theme="navigation"
                />
              </div>

              <div className="flex flex-col-reverse sm:flex-row items-center sm:space-x-2">
                <ButtonComponent
                  text="Cancel"
                  type="button"
                  onClick={() => navigate('/dashboard/locations')}
                  theme="cancel"
                  className='w-full sm:w-auto'
                />
                <ButtonComponent
                  text="Delete"
                  type="button"
                  onClick={handleDelete}
                  theme="danger"
                  className='w-full sm:w-auto mb-2 sm:mb-0'
                />
                <ButtonComponent
                  text="Save"
                  type="submit"
                  theme="primary"
                  className='w-full sm:w-auto mb-2 sm:mb-0'
                  // onClick={}
                />
                {/* <ButtonComponent
                  text={isLoading ? 'Submitting...' : 'Submit for Approval'}
                  type="submit"
                  theme="accept"
                  disabled={isLoading || !Object.values(sectionStatus).every((status) => status)}
                  className={`w-full mb-2 sm:mb-0 sm:w-auto ${
                    isLoading || !Object.values(sectionStatus).every((status) => status)
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                  title={
                    !Object.values(sectionStatus).every((status) => status)
                      ? 'Please complete all sections before submitting'
                      : ''
                  }
                /> */}
              </div>
            </div>
          </form>
        </div>
        </>
      )}
    </div>
  );
}
