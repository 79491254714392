import React from "react";
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';

export default function LocationSettings( {locationData, setLocationData, handleSliderChange, handleAdjustMinSpend, onClick} ) {
  return (
		<div className="border-b border-gray-300 pb-4">
		<h2 className="text-xl font-semibold mb-4">Settings
		<QuestionMarkCircleIcon 
				className="h-5 w-5 inline-block text-gray-500 ml-1 cursor-pointer"
				onClick={() => onClick("In this section you can set the commission, discount and minimum spend of your location. You can also turn off the QR code so that it does not appear to users.")}
				/>
		</h2>

		{/* Hide QR Code */}
		<div className="my-2">
			<label className="flex items-center space-x-2">
				<input
					type="checkbox"
					name="hideQRCode"
					checked={locationData.hideQrCode}
					onChange={(e) => setLocationData({ ...locationData, hideQrCode: e.target.checked })}
				/>
				<span>Hide QR Code</span>
		</label>
		</div>

		{/* Slider's Section */}
		<div className="mb-4">
			<label className="block mb-2 text-sm font-medium">Commission (1% Increments, min 5%)</label>
			<input
				type="range"
				min="5"
				max="40"
				value={locationData.commission}
				onChange={(e) => handleSliderChange('commission', parseFloat(e.target.value))}
				className="w-full"
			/>
			<p>{locationData.commission}%</p>
		</div>

		<div className="mb-4">
			<label className="block mb-2 text-sm font-medium">Discount (2.5% Increments, min 10%)</label>
			<input
				type="range"
				min="10"
				max="30"
				step={2.5}
				value={locationData.discount}
				onChange={(e) => handleSliderChange('discount', parseFloat(e.target.value))}
				className="w-full"
			/>
			<p>{locationData.discount}%</p>
		</div>

			{/* Min Spend Section */}
			<div>
				<label className="block mb-2 text-sm font-medium">
					Minimum Spend (min £4.99)
				</label>

				<div className="flex flex-wrap items-center justify-center">
					<input
						type="text"
						value={locationData.minSpend}
						readOnly
						className="w-full px-3 py-2 mb-2 text-center rounded-md bg-gray-100 border order-1 border-gray-300 sm:order-2 sm:w-auto sm:mb-0"
					/>

					<div className="flex flex-wrap justify-center flex-col-reverse w-full sm:space-x-0 order-3 sm:order-1 sm:flex-row sm:w-auto">
						<button
							type='button'
							onClick={() => handleAdjustMinSpend(-5.0)}
							className="px-3 py-2 bg-red-500 text-white"
						>
							-5.00
						</button>
						<button
						type='button'
							onClick={() => handleAdjustMinSpend(-1.0)}
							className="px-3 py-2 bg-red-400 text-white"
						>
							-1.00
						</button>
						<button
						type='button'
							onClick={() => handleAdjustMinSpend(-0.5)}
							className="px-3 py-2 bg-red-300 text-white"
						>
							-0.50
						</button>
					</div>

					<div className="flex flex-wrap justify-center w-full order-2 flex-col sm:space-x-0 sm:order-3 sm:w-auto sm:flex-row">
						<button
						type='button'
							onClick={() => handleAdjustMinSpend(0.5)}
							className="px-3 py-2 bg-green-300 text-white"
						>
							+0.50
						</button>
						<button
						type='button'
							onClick={() => handleAdjustMinSpend(1.0)}
							className="px-3 py-2 bg-green-400 text-white"
						>
							+1.00
						</button>
						<button
						type='button'
							onClick={() => handleAdjustMinSpend(5.0)}
							className="px-3 py-2 bg-green-500 text-white"
						>
							+5.00
						</button>
					</div>
				</div>
			</div>
	</div>
  );
}