import React, { useState, useRef, useEffect } from "react";
import ButtonComponent from "./ButtonComponent";

const Validation = ({ isOpen, onClose, message, onValidate, onResend, validationError='' }) => {
  const [code, setCode] = useState(["", "", "", ""]);
  const inputsRef = useRef([]);

  useEffect(() => {
    if (code.every((digit) => digit !== "")) {
      onValidate(code.join(""));
    }
  }, [code, onValidate]);

  if (!isOpen) return null;

  const handleInputChange = (index, value) => {

    const newCode = [...code];
    newCode[index] = value.toUpperCase();
    setCode(newCode);

    if (value && index < 3) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
      <div className="bg-white bg-opacity-100 p-8 rounded-lg shadow-2xl max-w-sm w-full text-black">
        <h2 className="text-2xl font-bold mb-4 text-center">Validation</h2>
        {validationError && <p className="text-red-500 mb-4 text-center">{validationError}</p>}
        <p className="text-center mb-6">{message}</p>

        {/* Code Input Boxes */}
        <div className="flex justify-center space-x-2 mb-6">
          {code.map((digit, index) => (
            <input
              key={index}
              ref={(el) => (inputsRef.current[index] = el)}
              type="tel"
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              maxLength={1}
              className="w-12 h-12 text-center text-2xl font-bold border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              inputMode="numeric"
            />
          ))}
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between space-x-4">
          <ButtonComponent
            className="w-full py-2 px-4"
            onClick={onResend}
            text="Resend Code"
            theme="primary"
          />
          <ButtonComponent
            className="w-full py-2 px-4"
            onClick={() => onValidate(code.join(""))}
            text="Validate"
            theme="accept"
          />
        </div>

        <ButtonComponent
          className="mt-6 w-full py-2 px-4"
          onClick={onClose}
          text="Close"
          theme="danger"
        />
      </div>
    </div>
  );
};

export default Validation;
