import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { loadLocations } from '../services/apiServices';
import useTokenValidator from '../services/useTokenValidator';
import TokenManager from '../services/tokenManager';
import LocationTile from '../components/LocationTile';
import ButtonComponent from '../components/ButtonComponent';
import Header from '../components/Header';

export default function Locations() {
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const isTokenLoading = useTokenValidator();

  useEffect(() => {
    if (isTokenLoading) return;

    loadLocations({
      access_token: TokenManager.getToken(),
    })
    .then((response) => {
      if (response.status === 200 && response.data.status === 'success') {
        setError('');
        setLocations(response.data.locations);
      } else {
        setError(response.message || 'Retrieval failed');
      }
    })
    .catch((err) => {
      console.error(err);
      setError(err.response?.data?.message || 'Retrieval failed');
    })
    .finally(() => {
      setLoading(false);
    });
  }, [isTokenLoading]);

  return (
    <div className="relative bg-white flex items-center flex-col min-h-screen">
      <Header />
      <h1 className="text-4xl font-bold text-center mb-5 mt-4">Locations</h1>

      <div className="flex justify-center gap-4 mb-4">
        <Link to="/dashboard">
        <ButtonComponent
          text="Back to Dashboard"
          theme="cancel"
        />
        </Link>
        <Link to="./add-location">
        <ButtonComponent
          text="Add New Location"
          theme="accept"
        />
        </Link>
      </div>

      {error && <p className="text-red-500 text-center mb-4">{error}</p>}

      {loading ? (
        <div className="flex items-center justify-center">
          <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          <p className="text-gray-700 text-xl ml-4">Loading Locations...</p>
        </div>
      ) : locations.length === 0 ? (
        <p className="text-center text-gray-500 mx-3">
          No locations available. You can add a new location by clicking "Add New Location" above.
        </p>
      ) : (
        <>
          {/* <p>Complete Locations</p> */}
          <div className="overflow-y-auto flex-grow w-full max-w-4xl">
          <ul className="flex flex-col sm:flex-row items-center justify-center sm:flex-wrap w-full gap-4 px-5 py-5">
            {locations.map((location) => (
              <LocationTile key={location.doc_ref} location={location} />
            ))}
          </ul>
        </div>
        {/* <p>Incomplete Locations</p> */}
        </>
      )}
    </div>
  );
}
