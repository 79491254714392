import React from "react";
import ButtonComponent from "./ButtonComponent";
import { CheckCircleIcon, ClockIcon, XCircleIcon } from "@heroicons/react/solid";
import GC from "../media/GC.png";

export default function InvoicePopup({ invoice, onClose, className }) {
  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const d = new Date(date);
    return d.toLocaleDateString(undefined, options);
  };

  const getStatusStyles = (status) => {
    switch (status.toLowerCase()) {
      case "paid":
        return {
          color: "bg-green-100 text-green-700",
          icon: <CheckCircleIcon className="h-5 w-5 inline" />,
        };
      case "pending":
        return {
          color: "bg-yellow-100 text-yellow-700",
          icon: <ClockIcon className="h-5 w-5 inline" />,
        };
      case "overdue":
        return {
          color: "bg-red-100 text-red-700",
          icon: <XCircleIcon className="h-5 w-5 inline" />,
        };
      default:
        return {
          color: "bg-gray-100 text-gray-700",
          icon: <ClockIcon className="h-5 w-5 inline" />,
        };
    }
  };

  if (!invoice) return null;

  const statusStyles = getStatusStyles(invoice.status);

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 z-20 flex justify-center items-center ${className}`}
      onClick={onClose}
    >
      <div
        className="p-6 bg-white border border-gray-300 rounded-lg shadow-lg mx-5 max-w-md w-full relative"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        {/* Header */}
        <h2 className="text-2xl font-bold mb-2 text-center">{invoice.invoice_name}</h2>
        <div className="border-b border-gray-200 mb-4"></div>

        {/* Amount Due */}
        <div className="flex justify-between items-center mb-4">
          <span className="text-lg font-semibold">Amount Due:</span>
          <span className="text-xl font-bold">
            {invoice.currency.symbol}
            {(invoice.amount_due / 100).toFixed(2)}
          </span>
        </div>

        {/* Invoice Dates */}
        <div className="space-y-2 mb-4 text-sm">
          <div className="flex justify-between">
            <span className="font-medium">Created At:</span>
            <span>{formatDate(invoice.created_at)}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium">Due Date:</span>
            <span>{formatDate(invoice.due_date)}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium">Last Updated:</span>
            <span>{formatDate(invoice.last_updated)}</span>
          </div>
        </div>

        {/* Status Field */}
        <div
          className={`flex items-center px-3 py-2 rounded-md mb-4 space-x-3 ${statusStyles.color}`}
        >
          {/* Status Icon and Text */}
          <div className="flex items-center">
            {statusStyles.icon}
            <span className="ml-2 capitalize font-medium">{invoice.status}</span>
          </div>

          {/* Status Message */}
          {invoice.status_message && (
            <span className="ml-3 text-sm italic text-gray-700 border-l border-gray-300 pl-3">
              {invoice.status_message}
            </span>
          )}
        </div>

        {/* Action Buttons */}
        <div className="flex flex-col flex-grow sm:flex-row justify-between items-center space-y-2 sm:space-x-4 mb-4">
          <ButtonComponent
            onClick={() => window.open(invoice.invoice_url, "_blank")}
            text="Download Invoice"
            theme="primary"
            className="w-full sm:w-auto"
          />
          <ButtonComponent
            onClick={() => window.open(invoice.payment_url, "_blank")}
            text="Pay Now"
            theme="accept"
            className="w-full sm:w-auto"
            disabled={invoice.status === "paid"}
          />
        </div>

        {/* Footer */}
        <div className="text-center">
          <span className="text-sm text-gray-500">Securely powered by</span>
          <img src={GC} alt="GoCard" className="mx-auto mt-1 w-24 h-auto" />
        </div>
      </div>
    </div>
  );
}