import React, { useEffect, useState } from 'react';
import { useAuth } from '../services/authContext';
import { useNavigate } from 'react-router-dom';
import { UserIcon, LocationMarkerIcon, CashIcon } from '@heroicons/react/solid';
import DashboardTile from '../components/DashboardTile';
import { checkBilling } from '../services/apiServices';
import TokenManager from '../services/tokenManager';
import useTokenValidator from '../services/useTokenValidator';
import ButtonComponent from '../components/ButtonComponent';
import Header from '../components/Header';
import { useNotification } from '../services/NotificationContext';

export default function Dashboard() {
  const navigate = useNavigate();
  const showNotification = useNotification();
  const [billingEmpty, setBillingEmpty] = useState(false);
  const { setIsAuthenticated, setBusinessName } = useAuth();

  const isTokenLoading = useTokenValidator();
  
  useEffect(() => {
    if (isTokenLoading) return;

    checkBilling({
      access_token: TokenManager.getToken(),
    })
    .then((response) => {
      if (response.status === 200 && response.data.complete === true) {
        setBillingEmpty(false);
      } else if (response.status === 200 && response.data.complete === false) {
        setBillingEmpty(true);
      }
    })
    .catch((err) => {
      console.log(err);
    });

  }, [isTokenLoading]);

  const handleLogout = () => {
    setIsAuthenticated(false);
    setBusinessName('');
    TokenManager.clearToken();
    showNotification('Logged out successfully', 'success');
    navigate('/login');
  };

  return (
      <div className="relative bg-white flex items-center flex-col min-h-screen">
        <Header />
        <h1 className="text-4xl font-bold text-center mb-8 mt-4">Dashboard</h1>

        <div className="flex flex-wrap justify-center mb-6 mx-5 gap-2">
          <DashboardTile 
            icon={<UserIcon className="h-12 w-12 md:h-16 mb-2 text-gray-700" />} 
            title="Account" 
            notification={false} 
            onClick={() => navigate('./account')} 
            className="w-full sm:w-auto" 
          />
          <DashboardTile 
            icon={<LocationMarkerIcon className="h-12 w-12 md:h-16 mb-2 text-gray-700" />} 
            title="Locations" 
            notification={false} 
            onClick={() => navigate('./locations')} 
            className="w-full sm:w-auto" 
          />
          <DashboardTile 
            icon={<CashIcon className="h-12 w-12 md:h-16 mb-2 text-gray-700" />} 
            title="Billing" 
            notification={billingEmpty} 
            onClick={() => navigate('./billing')} 
            className="w-full sm:w-auto" 
          />
        </div>

        <ButtonComponent 
          theme={"danger"} 
          text={"Log Out"} 
          onClick={handleLogout} 
          className="w-44 mx-auto" 
          />
    </div>
  );
}
