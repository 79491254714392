import React, { useEffect } from "react";
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';

export default function LocationImage( {locationData, setLocationData, googleImages, onClick, updateStatus, existingLocation = false},  ) {
	useEffect(() => {
		const isComplete = locationData.imageUrl.trim() !== "";
		updateStatus("image", isComplete);
	}, [locationData.imageUrl, updateStatus]);

	return (
		<div className="border-b border-gray-300 pb-4">
			<h2 className="text-xl font-semibold mb-4">Image Selection
			<QuestionMarkCircleIcon 
				className="h-5 w-5 inline-block text-gray-500 ml-1 cursor-pointer"
				onClick={() => onClick("In this section you can set the image of your location. This image will be shown to users when they view your location.")}
				/>
			</h2>
			<div className="flex items-center mb-4 space-x-4">
				<label className="flex items-center space-x-2">
					<input
						type="radio"
						name="imageSource"
						value="google"
						checked={locationData.useGoogleImage}
						onChange={() => {
							setLocationData({ ...locationData, useGoogleImage: true, imageUrl: googleImages[0] });
						}}
					/>
					<span>Use Default Google Image</span>
				</label>
				<label className="flex items-center space-x-2">
					<input
						type="radio"
						name="imageSource"
						value="manual"
						checked={!locationData.useGoogleImage}
						onChange={() => setLocationData({ ...locationData, useGoogleImage: false})}
					/>
					<span>Provide URL Manually</span>
				</label>
			</div>

			{existingLocation && locationData.useGoogleImage ? (
			<img
				alt="Default Google Images"
				className="h-80 w-80 mb-4"
				src={locationData.imageUrl}
			/>
			) : (
			locationData.useGoogleImage && (
				<img
				alt="Default Google Images"
				className="h-80 w-80 mb-4"
				src={googleImages[0]}
				/>
			)
			)}

			{!locationData.useGoogleImage && (
				<div className="mt-2">
					<label className="block mb-1 text-sm font-medium">Image URL</label>
					<input
						type="text"
						name="manualImageUrl"
						value={locationData.imageUrl}
						onChange={(e) => setLocationData({ ...locationData, imageUrl: e.target.value })}
						className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
						placeholder="Enter image URL"
					/>
				</div>
			)}
		</div>
	);
}
