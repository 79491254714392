import React from "react";

export default function ButtonComponent( {className = "", text = "Default", onClick, theme, type = "button", disabled = false, title} ) {

	const themeStyles = {
		primary: "py-3 px-5 bg-blue-500 hover:bg-blue-600 font-semibold rounded-md text-white transition duration-300",
    accept: "py-3 px-5 bg-lime-600 hover:bg-lime-700 font-semibold rounded-md text-white transition duration-300",
    danger: "py-3 px-5 bg-red-600 hover:bg-red-700 font-semibold rounded-md text-white transition duration-300",
    cancel: "py-3 px-5 bg-gray-500 hover:bg-gray-600 font-semibold rounded-md text-white transition duration-300",
    navigation: "py-3 px-5 bg-transparent hover:bg-gray-300 font-semibold rounded-md text-blue-500 transition duration-300"
  };

  const disabledStyles = "py-3 px-5 bg-gray-300 text-gray-500 cursor-not-allowed font-semibold rounded-md";

  const buttonClass = disabled 
    ? `${disabledStyles} ${className}` 
    : `${themeStyles[theme] || themeStyles.primary} ${className}`;

	return (
    <button
			className={`${buttonClass} ${className}`}
			type={type}
			disabled={disabled}
      onClick={onClick}
      title={title}
    >
      {text}
    </button>
	);
}