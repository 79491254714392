import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateBilling, loadBilling, loadInvoices } from '../services/apiServices';
import useTokenValidator from '../services/useTokenValidator';
import TokenManager from '../services/tokenManager';
import ButtonComponent from '../components/ButtonComponent';
import Header from '../components/Header';
import InvoicePopup from '../components/InvoicePopup';
import { useNotification } from "../services/NotificationContext";

export default function BillingInformation() {
  const navigate = useNavigate();
  const location = useLocation();
  const showNotification = useNotification();
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get('status');

  const [billingData, setBillingData] = useState({
    name: '',
    addressl1: '',
    addressl2: '',
    email: '',
    phoneNumber: '',
    vat: ''
  });
  const [error, setError] = useState('');
  const [isBillingLoading, setIsBillingLoading] = useState(true);
  const [reportFrequency, setReportFrequency] = useState({
    daily: false,
    weekly: false,
    monthly: false,
  });
  const [invoices, setInvoices] = useState([]);
  const [invoiceStatus, setInvoiceStatus] = useState({
    invoice: null,
    isOpen: false,
  });

  useEffect(() => {
    if (message === 'success') {
      showNotification("Invoice has been paid successfully", "success");
    } else if (message === 'failed') {
      showNotification("Failed to pay invoice", "error");
    }
  }, [message]);

  const isTokenLoading = useTokenValidator();

  useEffect(() => {
    if (isTokenLoading) return;

    setIsBillingLoading(true);
    loadBilling({
      access_token: TokenManager.getToken(),
    })
    .then((response) => {
      if (response) {
        const billingData = {
          name: response.data.billing_info.client_name,
          addressl1: response.data.billing_info.address_l1,
          addressl2: response.data.billing_info.address_l2 || '',
          email: response.data.billing_info.email,
          phoneNumber: response.data.billing_info.phone_number || '',
          vat: response.data.billing_info.vat_number || ''
        };
        const reportFrequency = {
          daily: response.data.billing_info.reports.send_daily_reports,
          weekly: response.data.billing_info.reports.send_weekly_reports,
          monthly: response.data.billing_info.reports.send_monthly_reports,
        };
        setBillingData(billingData);
        setReportFrequency(reportFrequency);
        setError('');
        setIsBillingLoading(false);
      } else {
        setError(response.message || 'Failed to load account info');
        setIsBillingLoading(false);
      }
    })
    .catch((err) => {
      console.error(err);
      setError('Failed to load account info');
      setIsBillingLoading(false);
    })
    .finally(() => {
      setIsBillingLoading(false);
    });
  }, [isTokenLoading]);

  useEffect(() => {
    if (isTokenLoading) return;

    loadInvoices({
      access_token: TokenManager.getToken(),
    }).then((response) => {
      if (response.data.status === "success") {
        setInvoices(response.data.invoices);
      } else {
        setError(response.message || 'Failed to load invoices');
      }
    })
    .catch((err) => {
      console.error(err);
      setError('Failed to load invoices');
    });
  }, [isTokenLoading]);

  const viewInvoice = (index) => {
    if(invoiceStatus.isOpen) {
      setInvoiceStatus({
        invoice: null,
        isOpen: false,
      });
    } else {
      setInvoiceStatus({
        invoice: invoices[index],
        isOpen: true,
      });
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBillingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleReportFrequencyChange = (e) => {
    const { name, checked } = e.target;
    setReportFrequency((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    updateBilling({
      access_token: TokenManager.getToken(),
      billing_info: {
        client_name: billingData.name,
        address_l1: billingData.addressl1,
        address_l2: billingData.addressl2,
        email: billingData.email,
        phone_number: billingData.phoneNumber,
        vat_number: billingData.vat,
        reports: {
          send_daily_reports: reportFrequency.daily,
          send_weekly_reports: reportFrequency.weekly,
          send_monthly_reports: reportFrequency.monthly,
        },
      },
    })
    .then((response) => {
      if (response.status === 200) {
        setError('');
        showNotification("Billing information updated successfully", "success");
        navigate('/dashboard');
      } else {
        setError(response.message || 'Failed to update account info');
      }
    })
    .catch((err) => {
      console.error(err);
      setError('Failed to update account info');
    });
  };

  return (
    <div className="relative bg-white flex items-center flex-col min-h-screen">
      <Header />
      <h1 className="text-4xl font-bold text-center mb-8 mt-4">Billing Information</h1>

      {error && <p className="text-red-500 text-center mb-4">{error}</p>}

      <InvoicePopup className={`${invoiceStatus.isOpen ? "" : "hidden"}`} invoice={invoiceStatus.invoice} onClose={viewInvoice} />

      {isBillingLoading ? (
        <div className="flex items-center justify-center">
          <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          <p className="text-gray-700 text-xl ml-4">Loading Billing...</p>
        </div>
      ) : (
        <div className='w-full max-w-2xl mx-auto px-5 flex-grow mb-5'>
        <form onSubmit={handleSubmit} className="space-y-3">

          {/* Basic Information Section */}
          <div className="border-b border-gray-300 pb-4">
            <h2 className="text-xl font-semibold mb-4">Basic Information</h2>
            <div className="mb-4">
              <label className="block mb-1 text-sm font-medium">Full Name or Business Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={billingData.name}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                required
              />
            </div>

            <div>
              <label className="block mb-1 text-sm font-medium">Address (Street Address)
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="addressl1"
                value={billingData.addressl1}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                required
              />
            </div>
            <div>
              <label className="block mb-2 text-base font-medium">Address (City, Country, Post Code)</label>
              <input
                type="text"
                name="addressl2"
                value={billingData.addressl2}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
              />
            </div>
            <div>
              <label className="block mb-2 text-base font-medium">Email
                <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                value={billingData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                required
              />
            </div>
            <div>
              <label className="block mb-2 text-base font-medium">Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={billingData.phoneNumber}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
              />
            </div>
            <div>
              <label className="block mb-2 text-base font-medium">Tax ID or VAT Number</label>
              <input
                type="text"
                name="vat"
                value={billingData.vat}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
              />
            </div>
          </div>

          {/* Reports Section */}
          <div className="border-b border-gray-300 pb-4">
            <h2 className="text-xl font-semibold mb-4">Reports</h2>
            <div className="mb-4">
              <label className="block mb-1 text-sm font-medium">How often would you like to receive reports?</label>
              <div className="space-y-2">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="daily"
                    name="daily"
                    checked={reportFrequency.daily}
                    onChange={handleReportFrequencyChange}
                    className="mr-2"
                  />
                  <label htmlFor="daily">Daily</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="weekly"
                    name="weekly"
                    checked={reportFrequency.weekly}
                    onChange={handleReportFrequencyChange}
                    className="mr-2"
                  />
                  <label htmlFor="weekly">Weekly</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="monthly"
                    name="monthly"
                    checked={reportFrequency.monthly}
                    onChange={handleReportFrequencyChange}
                    className="mr-2"
                  />
                  <label htmlFor="monthly">Monthly</label>
                </div>
              </div>
            </div>
          </div>

          {/* Invoices Section */}
          <div className="border-b border-gray-300 pb-4">
            <h2 className="text-xl font-semibold mb-4">Invoices</h2>
            <div className="space-y-2">
            {invoices
              .slice()
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((invoice, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center bg-white text-gray-800 border border-gray-300 rounded-lg shadow-md hover:shadow-lg hover:bg-gray-200 transition duration-300 p-5 cursor-pointer"
                  onClick={() => viewInvoice(index)}
                >
                  <div>
                    <p className="font-semibold">{invoice.invoice_name}</p>
                    <p className="text-sm">
                      Amount: {invoice.currency.symbol}
                      {(invoice.amount_due / 100).toFixed(2)}
                    </p>
                    <p className="text-sm">Issue Date: {invoice.created_at}</p>
                  </div>
                  <div className="text-blue-500 text-sm">View</div>
                </div>
              ))}
              </div>
          </div>

          {/* Submit Buttons */}
          <div className="flex flex-wrap justify-between mt-4">
          <ButtonComponent
            text="Cancel"
            theme="cancel"
            onClick={() => navigate('/dashboard')}
            className="order-2 mt-2 sm:mt-0 sm:order-1 w-full sm:w-auto"
          />
          <ButtonComponent
            text="Update"
            theme="accept"
            type="submit"
            className="order-1 sm:order-3 w-full sm:w-auto sm:ml-3"
          />
          </div>
        </form>
      </div>
      )}
    </div>
  );
}
