import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TokenManager from "./tokenManager";
import { checkToken } from "./apiServices";
import { useAuth } from "./authContext";
import { useNotification } from "./NotificationContext";

const useTokenValidator = () => {
  const navigate = useNavigate();
  const showNotification = useNotification();
  const { setIsAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateToken = async () => {
      if (TokenManager.hasToken()) {
        try {
          const response = await checkToken({ access_token: TokenManager.getToken() });
          if (response.status === 200 && response.data.valid) {
            setIsAuthenticated(true);
          } else {
            handleInvalidToken();
          }
        } catch (error) {
          console.error("Token validation failed:", error);
          handleInvalidToken();
        }
      } else {
        handleInvalidToken();
      }
      setIsLoading(false);
    };

    const handleInvalidToken = () => {
      setIsAuthenticated(false);
      TokenManager.clearToken();
      showNotification("Session expired. Please login again.", "error");
      navigate("/login");
    };

    validateToken();
  }, [navigate, setIsAuthenticated]);

  return isLoading;
};

export default useTokenValidator;