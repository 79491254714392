import React, { createContext, useContext, useState } from "react";

const NotificationContext = createContext();

export function NotificationProvider({ children }) {
  const [notification, setNotification] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setIsVisible(true);

    setTimeout(() => setIsVisible(false), 5000);
    setTimeout(() => setNotification(null), 5500);
  };

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
      {notification && (
        <div
          className={`fixed top-0 left-0 w-full p-4 text-center transform transition-transform duration-500 ${
            isVisible ? "translate-y-0" : "-translate-y-full"
          } ${
            notification.type === "success" ? "bg-green-500" : "bg-red-500"
          } text-white font-semibold shadow-md`}
        >
          {notification.message}
        </div>
      )}
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  return useContext(NotificationContext);
}
