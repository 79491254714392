import React from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "./ButtonComponent";

export default function LocationTile({ location }) {

    return (
        <Link 
            to={`./${location.doc_ref}`} 
            key={location.doc_ref} 
            className="w-full sm:w-[calc(50%-1rem)]"
        >
            <li className="w-full flex h-85 flex-col items-center justify-start bg-white text-gray-800 border border-gray-300 rounded-lg shadow-md hover:shadow-lg hover:bg-gray-200 transition duration-300 overflow-hidden">
                {/* Image Section */}
                <div className="relative w-full h-40">
                    <img
                        src={location.image_settings.url}
                        alt="Building"
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 left-0 right-0 h-10 bg-gradient-to-t from-gray-800 to-transparent opacity-70" />
                </div>

                {/* Text Section */}
                <div className="flex-grow flex flex-col items-center mt-4 space-y-2 px-4">
                    <span className="font-semibold text-xl text-center overflow-hidden text-ellipsis">{location.location_name || "Name not available."}</span>
                    <span className="text-sm text-gray-400 text-center overflow-hidden overflow-ellipsis block" style={{ display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}>{location.address || "Address not available."}</span>
										<p className="text-sm text-gray-600 text-center overflow-hidden" style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', lineHeight: '1.2rem', maxHeight: '2.4rem' }}>
											{location.description || "Description not available."}
										</p>
                </div>

                {/* Button Section */}
                <div className="flex justify-center mt-4">
                    <ButtonComponent
                        text="Edit Location"
                        theme="primary"
                        className="px-6 py-2 mb-4"
                    />
                </div>
            </li>
        </Link>
    );
}
