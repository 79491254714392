import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadAccount, updateAccount } from '../services/apiServices';
import useTokenValidator from '../services/useTokenValidator';
import TokenManager from '../services/tokenManager';
import ButtonComponent from '../components/ButtonComponent';
import Header from '../components/Header';

export default function Account() {
  const [accountData, setAccountData] = useState({
    businessName: '',
    email: '',
    phoneNumber: '',
  });
  const [error, setError] = useState('');
  const [isAccountLoading, setIsAccountLoading] = useState(true);
  const navigate = useNavigate();

  const isTokenLoading = useTokenValidator();

  useEffect(() => {
    if (isTokenLoading) return;

    loadAccount({
      access_token: TokenManager.getToken(),
    })
      .then((response) => {
        if (response) {
          const data = {
            businessName: response.data.account.business_name,
            email: response.data.account.emails,
            phoneNumber: response.data.account.phone_numbers,
          };
          setAccountData(data);
        } else {
          setError(response.message || 'Failed to load account info');
        }
      })
      .catch((err) => {
        console.error(err);
        setError('Failed to load account info');
      })
      .finally(() => {
        setIsAccountLoading(false);
      });
  }, [isTokenLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateAccount({
      access_token: localStorage.getItem('token'),
      update_dict: {
        business_name: accountData.businessName,
        emails: accountData.email,
        phone_numbers: accountData.phoneNumber,
      },
    })
      .then((response) => {
        if (response.status === 'success') {
          setError('');
        } else {
          setError(response.message || 'Failed to update account info');
        }
      })
      .catch((err) => {
        console.error(err);
        setError('Failed to update account info');
      });
  };

  return (
    <div className="relative bg-white flex items-center flex-col min-h-screen">
      <Header />
      <h1 className="text-4xl font-bold text-center mb-8 mt-4">Account</h1>

      {error && <p className="text-red-500 text-center mb-4">{error}</p>}

      {isAccountLoading ? (
        <div className="flex items-center justify-center">
          <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          <p className="text-gray-700 text-xl ml-4">Loading Account...</p>
        </div>
      ) : (
        <div className='w-full max-w-2xl mx-auto px-5 flex-grow mb-5'>
        <form onSubmit={handleSubmit} className="space-y-3">
            <div>
              <label className="block mb-1 text-sm font-medium">Business Name</label>
              <input
                type="text"
                name="businessName"
                value={accountData.businessName}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                disabled={true}
              />
            </div>
            <div>
              <label className="block mb-2 text-base font-medium">Email</label>
              <input
                type="email"
                name="email"
                value={accountData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                disabled={true}
              />
            </div>
            <div>
              <label className="block mb-2 text-base font-medium">Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={accountData.phoneNumber}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
                disabled={true}
              />
            </div>

          <div className="flex flex-wrap justify-start mt-4">
            <ButtonComponent
              text="Cancel"
              theme="cancel"
              onClick={() => navigate('/dashboard')}
            />  

        {/* <ButtonComponent
            text="Update"
            theme="accept"
            type="submit"
            className="py-2 px-4"
            /> */}

          </div>
        </form>
      </div>
      ) }

    </div>
  );
}
