import React, { useEffect } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";

export default function LocationBasicInformation({ locationData, handleChange, onClick, updateStatus, existingLocation = false, handleAccessName, isNameValid, validationMessage }) {
  useEffect(() => {
    const isComplete = locationData.displayName.trim() !== "" && locationData.description.trim() !== "";
    updateStatus("basic", isComplete);
  }, [locationData.displayName, locationData.description, updateStatus]);

  return (
    <div className="border-b border-gray-300 pb-4">
      <h2 className="text-xl font-semibold mb-4">
        Basic Information
        <QuestionMarkCircleIcon
          className="h-5 w-5 inline-block text-gray-500 ml-1 cursor-pointer"
          onClick={() => onClick("In this section you can set the display name and description of your location that will be shown to users.")}
        />
      </h2>
      <div className="mb-4">
        <label className="block mb-1 text-md font-medium">Display Name</label>
        <input
          type="text"
          name="displayName"
          value={locationData.displayName}
          onChange={handleChange}
          className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
          required
        />
      </div>
      <div>
        <label className="block mb-1 text-md font-medium">Description</label>
        <textarea
          name="description"
          value={locationData.description}
          onChange={handleChange}
          className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
        />
      </div>
      {existingLocation && (
      <>
        <div>
          <label className="block mb-1 mt-3 text-md font-medium">Access Name</label>
          <input
            name="accessName"
            type="text"
            value={locationData.accessName}
            onChange={(e) => {
              const name = e.target.value.toUpperCase().replace(/[^A-Z0-9_]/g, "");
              handleAccessName(name);
            }}
            className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
          />
          <a
            className="text-sky-600 mt-2 inline-block"
            target="_blank"
            rel="noreferrer"
            href="https://redeem.nusmark.com"
          >
            https://redeem.nusmark.com
          </a>
          <div
            className={`mt-2 text-sm ${isNameValid ? 'text-green-600' : 'text-red-600'}`}
          >
            {validationMessage}
          </div>
        </div>
        <div>
          <label className="block mb-1 text-md font-medium">Access Code</label>
          <input
            name='accessCode'
            type="text"
            value={locationData.accessCode}
            onChange={handleChange}
            className="w-full px-3 py-2 rounded-md bg-slate-50 border border-gray-300 focus:outline-blue"
          />
        </div>
      </>
    )}
    </div>
  );
}