class TokenManager {

  static saveToken(token, keepLoggedIn) {
    if (keepLoggedIn) {
      localStorage.setItem("merchantToken", token);
    } else {
      sessionStorage.setItem("merchantToken", token);
    }
  }

  static getToken() {
    return localStorage.getItem("merchantToken") || sessionStorage.getItem("merchantToken");
  }

  static clearToken() {
    localStorage.removeItem("merchantToken");
    sessionStorage.removeItem("merchantToken");
  }

  static hasToken() {
    return !!this.getToken();
  }
}

export default TokenManager;
