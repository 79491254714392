import axios from 'axios';

const baseURL = 'https://discount-partner-service-1-sguhogpuha-uc.a.run.app';

// Status check
export const statusCheck = () => {
	return axios.get(`${baseURL}/`);
};

// Account Management
export const register = (userData) => {
	return axios.post(`${baseURL}/create_account`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const loadAccount = (userData) => {
	return axios.post(`${baseURL}/get_account_details`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const resendEmail = (userData) => {
	return axios.post(`${baseURL}/resend_verification_email`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const verify = (userData) => {
	return axios.post(`${baseURL}/verify_account`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const isVerified = (userData) => {
	return axios.post(`${baseURL}/is_verified`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const login = (userData) => {
	return axios.post(`${baseURL}/verify_login`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const updateAccount = (userData) => {
	return axios.post(`${baseURL}/update_account`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

// Location Management
export const addLocation = (userData) => {
	return axios.post(`${baseURL}/add_location`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const loadLocations = (userData) => {
	return axios.post(`${baseURL}/load_locations`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const loadLocation = (userData) => {
	return axios.post(`${baseURL}/load_location`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const editLocation = (userData) => {
	return axios.post(`${baseURL}/edit_location`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const checkAccessName = (userData) => {
	return axios.post(`${baseURL}/location_is_access_name_taken`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const deleteLocation = (userData) => {
	return axios.post(`${baseURL}/delete_location`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const archiveImage = (userData) => {
	return axios.post(`${baseURL}/archive_image`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

// Token Management
export const checkToken = (userData) => {
	return axios.post(`${baseURL}/check_token`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

// Billing Management
export const updateBilling = (userData) => {
	return axios.post(`${baseURL}/billing_update`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const checkBilling = (userData) => {
	return axios.post(`${baseURL}/billing_is_complete`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const loadBilling = (userData) => {
	return axios.post(`${baseURL}/billing_get`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const loadInvoices = (userData) => {
	return axios.post(`${baseURL}/load_invoices`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};