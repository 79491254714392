import React from "react";

export default function DashboardTile({ icon, title, notification, onClick }) {
	return (
		<button
			onClick={onClick}
			className="relative flex flex-col items-center justify-center h-44 w-44 bg-white text-gray-800 border border-gray-300 rounded-lg shadow-md hover:shadow-lg hover:bg-gray-200 transition duration-300"
		>
			{notification && (
				<span className="absolute top-3 right-3 h-3 w-3 bg-red-500 rounded-full"></span>
			)}
			{icon}
			<span className="text-lg font-medium">{title}</span>
		</button>
	);  
}