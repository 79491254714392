import React from "react";
import AddressInput from '../AddressInput';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';

export default function LocationAddress( {handleAddressSelect, handleChange, onClick, locationData} ) {
    return (
			<div className="border-b border-gray-300 pb-4">
				<h2 className="text-xl font-semibold mb-4">Address
					<QuestionMarkCircleIcon 
					className="h-5 w-5 inline-block text-gray-500 ml-1 cursor-pointer"
          onClick={() => onClick("In this section you can set the address of your location. This address will be used to show relevant location data to users. Please note that you need to select an address from the dropdown to proceed.")}
					/>
				</h2>
				<label className="block mb-1 text-sm font-medium">Address</label>
				<AddressInput text={locationData.address} onAddressSelect={handleAddressSelect} onManualChange={handleChange} />
			</div>
    );
}
